// Vendor imports
import React from 'react';
import PropTypes from 'prop-types';

// Local imports
import { Modal, ModalBody, ModalFooter } from './Modal';
import Input from './Input';
import Button from './Button';

import '../styles.css';

/**
 * The password change modal component; passes most logic up to the parent component
 */
class PasswordChangeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errorMessage: props.errorMessage,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.checkIsSubmit = this.checkIsSubmit.bind(this);
    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorMessage) {
      this.setState({
        errorMessage: nextProps.errorMessage,
      });
    }
  }

  // On input change, map the input's value to it's name in state
  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      if (this.state.newPassword !== this.state.confirmNewPassword) {
        this.setState({ errorMessage: 'New password and confirmation must match' });
      } else {
        this.setState({ errorMessage: null });
      }
    });
  }

  onChangePasswordClick() {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    this.props.onChangePasswordClick({ oldPassword, newPassword, confirmNewPassword });
  }

  checkIsSubmit(e) {
    if (e.keyCode === 13 && this.isChangePasswordDisabled() === false) { // keyCode 13 = enter
      this.onChangePasswordClick();
    }
  }

  isChangePasswordDisabled() {
    return this.props.loading
      || !this.state.oldPassword
      || !this.state.newPassword
      || !this.state.confirmNewPassword
      || this.state.newPassword !== this.state.confirmNewPassword;
  }

  render() {
    return (
      <Modal
        open={this.props.show}
        title="Change Password"
        onClose={this.props.onChangePasswordCancel}
      >
        <ModalBody>
          <p className="htr-error">{this.state.errorMessage}</p>

          <Input
            name="oldPassword"
            type="password"
            placeholder="Old Password"
            onKeyDown={this.checkIsSubmit}
            onChange={this.onInputChange}
            fullWidth
          />
          <Input
            name="newPassword"
            type="password"
            placeholder="New Password"
            onKeyDown={this.checkIsSubmit}
            onChange={this.onInputChange}
            fullWidth
          />
          <Input
            name="confirmNewPassword"
            type="password"
            placeholder="Confirm New Password"
            onKeyDown={this.checkIsSubmit}
            onChange={this.onInputChange}
            fullWidth
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="secondary"
            onClick={this.props.onChangePasswordCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={this.onChangePasswordClick}
            disabled={this.isChangePasswordDisabled()}
          >
            {this.props.loading ? 'Loading' : 'Change Password'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

PasswordChangeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onChangePasswordCancel: PropTypes.func.isRequired,
  onChangePasswordClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default PasswordChangeModal;
